export default function (Component) {
  Component.__i18nGlobal = Component.__i18nGlobal || []
  Component.__i18nGlobal.push({
    "locale": "",
    "resource": {
      "fr": {
        "search": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche"])},
          "advanced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
          "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par"])},
          "object_types": {
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
            "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
            "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
            "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
            "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix littéraire"])},
            "store_item": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])}
          }
        },
        "book_format": {
          "name": {
            "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
            "epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB"])},
            "pdf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF"])},
            "audiobook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre audio"])},
            "mp3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre audio"])},
            "epub_fixed_layout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EPUB (mise en page fixe)"])}
          }
        },
        "offers": {
          "other_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres licences pour cette publication"])},
          "incompatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre portail de prêt est incompatible avec cette publication"])},
          "previous_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une ou plusieurs licences de ce titre ont déjà été acquises par votre organisation"])},
          "group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce livre fait partie de la collection partagée"])},
          "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["détails"])},
          "more_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus de détails"])},
          "fewer_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de détails"])},
          "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactiver"])},
          "disable_confirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment désactiver cette offre? Cette opération est irréversible. Il est aussi possible que cette offre soit réactivée lors d'une importation ultérieure."])},
          "admin": {
            "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retirer l'item du panier"])},
            "retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réessayer de commander cet item"])},
            "current_price": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Prix actuel de l'offre: ", _interpolate(_named("price"))])},
            "new_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau prix"])},
            "update_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
            "disable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactiver l'offre"])}
          },
          "carts": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
            "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
            "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier recommandé"])},
            "disclaimer_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette liste de recommandations est générée via"])},
            "disclaimer_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elle peut contenir des **livres populaires dans votre bibliothèque** dont la licence serait à renouveler, des **livres absents de votre catalogue** qui pourraient intéresser les lecteurs sur la base de leurs préférences, les livres **les plus populaires dans d'autres bibliothèques**, mais absents de votre catalogue."])},
            "banner_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 manières simples d’enrichir votre catalogue"])},
            "banner_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez des paniers déjà créés pour vous faciliter la vie. Dans ces paniers se trouvent les livres :"])},
            "banner_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1. Les plus populaires dans votre bibliothèque dont la licence serait à renouveler"])},
            "banner_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2. Absents de votre catalogue qui pourraient intéresser les lecteurs sur la base de leurs préférences"])},
            "banner_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3. Les plus populaires dans d'autres bibliothèques, mais absents de votre catalogue"])},
            "customer_service_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier Service Client"])},
            "customer_service_text_html": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ce panier a été validé par notre équipe Succès Client. ", _interpolate(_named("br")), " Les exemplaires de tous les titres ci-dessous sont disponibles dans votre catalogue mais ne seront pas facturés à votre organisation."])}
          },
          "cart_orders": {
            "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])},
            "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
            "distributor_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur de l'offre"])},
            "formatted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formaté"])},
            "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de la table"])},
            "order_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiant de la commande"])},
            "provider_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur de l'offre"])},
            "provider_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distributeur de la publication"])},
            "query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres de la requête"])},
            "raw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brut"])},
            "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réponse"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes de paniers de bibliothèques"])},
            "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
            "url_unknown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL non définie"])}
          }
        },
        "cart": {
          "refund": {
            "reason": {
              "faulty_files": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers défectueux"])},
              "ordered_for_the_wrong_library": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandé pour la mauvaise bibliothèque"])},
              "technical_issue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Problème technique"])},
              "operational_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur opérationnelle"])},
              "selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])}
            },
            "select_a_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un motif"])},
            "refund_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode de remboursement"])},
            "exit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quitter"])},
            "button_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rembourser"])},
            "confirm_window": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le remboursement"])},
              "table": {
                "unit_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire"])},
                "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
                "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
                "refund_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du remboursement'"])},
                "reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif"])}
              },
              "form": {
                "label_adicional_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails à inclure dans le courriel de notification client (facultatif)"])},
                "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte pertinent pour les clients (bibliothèque, réseau, libraire, etc.)"])},
                "button_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
                "button_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le remboursement"])}
              }
            },
            "refund_copies": {
              "title": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Exemplaire remboursé"]), _normalize(["Exemplaires remboursés"])])},
              "message_download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le résumé du remboursement"])},
              "date_of_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date du remboursement"])}
            },
            "refund_cofirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursement confirmé"])}
          },
          "confirm_validation": {
            "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comme toutes les ventes sont **définitives et non remboursables**, veuillez prendre un moment pour vous assurer que votre commande correspond exactement à ce que vous souhaitez."])},
            "particularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notamment"])},
            "list": {
              "prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les prix"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les quantités"])},
              "formats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les formats"])},
              "licenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["les types de licences"])}
            },
            "checkbox_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai compris et je confirme que tous les items de ma commande sont conformes à mon intention d'achat."])}
          },
          "license": {
            "no_other_license_type_is_available_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun autre type de licence n'est présentement disponible pour cette publication"])},
            "select_the_desired_license_type_and_click_the_replace_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de licence souhaité et cliquez sur le bouton Remplacer"])},
            "see_other_available_license_types_for_this_publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les autres types de licences disponibles pour cette publication"])}
          },
          "move_cart": {
            "available_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques disponibles"])},
            "available_library_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usagers de la bibliothèque"])},
            "confirm_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment déplacer ces éléments vers ce panier?"])},
            "manage_users": {
              "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a pas d'utilisateur associé à cette bibliothèque"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer les utilisateurs"])}
            },
            "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer à une autre bibliothèque"])}
          },
          "retailer": {
            "retailers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Librairies"])}
          },
          "sub_library": {
            "sub_libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation avancée"])}
          },
          "item_status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre déjà achetée"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre déjà présente dans un autre panier"])},
            "refunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remboursé"])},
            "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])}
          },
          "unavailable_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au moins un titre contenu dans votre panier n'est plus disponible: ses conditions de vente ont été modifiées par le fournisseur depuis que vous l'avez ajouté."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez supprimer de votre panier le ou les titres suivants afin de poursuivre la validation:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ces titres"])},
            "footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous sommes désolés pour cet inconvénient."])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Information importante concernant votre panier"])},
            "show_more": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher plus"])},
            "show_less": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher moins"])}
          },
          "incompatible_offers": {
            "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paniers Service Client ne peuvent contenir que des titres provenant d'une source opérée par De Marque."])},
            "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez supprimer les titres suivants du panier pour qu'il puisse être validé:"])},
            "delete_offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer ces titres"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La validation du panier a échoué"])}
          },
          "loans": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 prêt"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prêts"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêts illimités"])},
            "loan": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named, plural: _plural } = ctx;return _plural([_normalize(["1 prêt"]), _normalize([_interpolate(_named("count")), " prêts"])])}
          },
          "loans_concurrent_users": {
            "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un à la fois"])},
            "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " simultanés"])},
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simultanés illimités"])}
          },
          "loan_rules": {
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de prêt"])},
            "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prêts"])},
            "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêts simultanés"])},
            "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la licence"])},
            "max_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée maximale d'un prêt"])}
          },
          "loans_lifetime": {
            "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée illimitée"])}
          },
          "lifetime": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
            "name": {
              "one_week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 semaine"])},
              "one_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois"])},
              "two_months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 mois"])},
              "one_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
              "two_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 ans"])},
              "two_to_five_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2-5 ans"])},
              "five_to_ten_years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["5-10 ans"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Illimité"])}
            }
          },
          "datetime": {
            "distance_in_words": {
              "half_a_minute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["une demi-minute"])},
              "less_than_x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moins de 1 seconde"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["moins de ", _interpolate(_named("count")), " secondes"])}
              },
              "x_seconds": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 seconde"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " secondes"])}
              },
              "less_than_x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moins de 1 minute"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["moins de ", _interpolate(_named("count")), " minutes"])}
              },
              "x_minutes": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 minute"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " minutes"])}
              },
              "about_x_hours": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["environ 1 heure"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["environ ", _interpolate(_named("count")), " heures"])}
              },
              "x_days": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 jour"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jours"])}
              },
              "about_x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["environ 1 mois"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["environ ", _interpolate(_named("count")), " mois"])}
              },
              "x_months": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " mois"])}
              },
              "about_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["environ 1 an"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["environ ", _interpolate(_named("count")), " ans"])}
              },
              "over_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["plus de 1 an"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["plus de ", _interpolate(_named("count")), " ans"])}
              },
              "almost_x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["presque 1 an"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["presque ", _interpolate(_named("count")), " ans"])}
              },
              "x_years": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ans"])}
              },
              "x_years_or_more": {
                "one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an ou plus"])},
                "other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ans ou plus"])}
              },
              "x_years_and_days": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("years")), " ans et ", _interpolate(_named("days")), " jours"])}
            }
          },
          "status": {
            "already_purchased": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre déjà achetée"])},
            "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec"])},
            "in_another_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre déjà présente dans un autre panier"])},
            "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
            "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
            "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validée"])}
          },
          "copies": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " exemplaires"])},
          "lines": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " lignes"])},
          "copy": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("copies")), " exemplaire"])},
          "line": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("lines")), " ligne"])},
          "cart_item": {
            "status": {
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
              "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échoué"])},
              "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
              "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
              "to_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de traitement"])},
              "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])}
            }
          },
          "total_with_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TTC"])},
          "cart_validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le panier"])},
          "back_button_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paniers"])},
          "action_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
          "cart_destroy_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce panier?"])},
          "cart_reprocess_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Voulez-vous vraiment revalider ce panier?"])},
          "actions": {
            "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer le panier"])},
            "delete_items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
            "export_csv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en format CSV"])},
            "export_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter en format Excel"])},
            "import_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer des ISBN"])},
            "move_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférer"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer"])},
            "reprocess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revalider le panier"])},
            "duplicate_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dupliquer le panier"])},
            "resend_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer le courriel de confirmation"])},
            "show_processing_logs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les logs du traitement"])},
            "process_a_refund": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traiter un remboursement"])},
            "show_cart_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique du panier"])}
          },
          "paper_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN papier:"])},
          "offer_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN de l'offre:"])},
          "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
          "offer": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
            "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])}
          },
          "form": {
            "move_items": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les offres sélectionnées ont été déplacées dans le panier suivant:"])},
              "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les offres sélectionnées ont été copiées dans le panier suivant:"])}
            },
            "destination_cart_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau panier"])},
            "current_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paniers existants"])},
            "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
            "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
            "libraries_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paniers de bibliothèque"])},
            "my_carts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes paniers"])},
            "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
          },
          "header": {
            "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
            "move_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer"])},
            "copy_items_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
            "select_destination_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un panier de destination"])},
            "sort_by_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par"])},
            "confirm_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer les éléments sélectionnés?"])},
            "labels": {
              "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre / offre"])},
              "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire"])},
              "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de parution"])},
              "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État(s)"])},
              "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])}
            },
            "sort": {
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
              "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
              "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de parution"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
            }
          },
          "cart_item_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])},
          "cart_item_details_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'offre"])},
          "cart_item_details_modal_ownership_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership model"])},
          "cart_item_details_modal_ownership_text_html": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This offer is not a license. The purchasing library owns the digital copy of the title they acquire. To review the rights and responsibilities that ownership confers, please see these <a href=\"https://ebooks.dp.la/digital-ownership-agreement/\" target=\"_blank\">detailed terms of the DPLA Ownership model</a>. To learn more, please consult these <a href=\"https://ebooks.dp.la/ownership/\" target=\"_blank\">Frequently Asked Questions</a>."])},
          "cart_item_details_modal_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
          "cart_item_details_modal_file_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])},
          "cart_item_details_modal_loan_rules": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de prêt"])},
          "cart_item_details_modal_loan_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prêts"])},
          "cart_item_details_modal_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs simultanés"])},
          "cart_item_details_modal_license_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de la licence"])},
          "cart_item_details_modal_license_duration_for_ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offer duration"])},
          "cart_item_details_modal_license_unlimited_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée illimitée"])},
          "cart_item_details_modal_loan_maximum_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée maximale du prêt"])},
          "cart_item_details_modal_streaming": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En continu"])},
          "cart_item_details_modal_streaming_concurrent_users_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'utilisateurs simultanés"])},
          "cart_item_details_modal_protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])},
          "cart_item_details_modal_protection_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de protection"])},
          "cart_item_details_modal_authorized_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils autorisés"])},
          "cart_item_details_modal_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier/coller"])},
          "cart_item_details_modal_print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impression"])},
          "ownership": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ownership"])},
          "there_is_no_offer_in_this_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il n'y a aucun article dans votre panier."])},
          "carts": {
            "group_purchases": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce livre fait partie de la collection partagée"])},
            "list_items": {
              "ajax_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, nous éprouvons un problème. Nous allons actualiser cette page pour que vous puissiez réessayer."])},
              "confirm_delete_titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voulez-vous vraiment supprimer ces titres?"])},
              "one_title_min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez sélectionner au moins un titre à supprimer"])}
            },
            "lists": {
              "titles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titres"])}
            },
            "currencies": {
              "CAD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " CAD"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAD"])}
              },
              "CHF": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " CHF"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CHF"])}
              },
              "EUR": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " €"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["€"])}
              },
              "GBP": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " £"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["£"])}
              },
              "USD": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " $"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["$"])}
              },
              "ARS": {
                "formatted_text": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("amount")), " ARS"])},
                "symbol": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ARS"])}
              }
            },
            "details": {
              "loans_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 prêt"])},
              "loans_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " prêts"])},
              "loans_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prêts illimités"])},
              "loans_concurrent_users_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un à la fois"])},
              "loans_concurrent_users_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " simultanés"])},
              "loans_concurrent_users_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simultanés illimités"])},
              "loans_lifetime_unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée illimitée"])},
              "x_days_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 jour"])},
              "x_days_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " jours"])},
              "x_years_one": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 an"])},
              "x_years_other": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("count")), " ans"])},
              "denied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
              "unlimited": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["illimité"])}
            },
            "service_fees": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Frais d’accès et de verrous pour les livres numériques et audionumériques (", _interpolate(_named("rate")), "%)"])},
            "sub_total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total sans taxes"])},
            "total_by_currency": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Total ", _interpolate(_named("currency"))])},
            "total_without_taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total sans taxes"])},
            "tax_rates": {
              "gst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TPS"])},
              "hst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVH"])},
              "pst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVP"])},
              "qst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVQ"])},
              "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])}
            }
          }
        },
        "not_found": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page introuvable"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La page que vous recherchez n'existe pas."])}
        },
        "components": {
          "accessibility_features_details": {
            "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Caractéristiques d'accessibilité ", _interpolate(_named("format"))])},
            "license_accessibility": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Accessibilité des licences ", _interpolate(_named("book_format"))])},
            "accessibility_feature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques d'accessibilité"])},
            "accessibility_other_fh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres caractéristiques et dangers"])},
            "no_accessibility_data_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune donnée d'accessibilité disponible"])}
          },
          "offer_details": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'offre"])},
            "group": {
              "format": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
                "columns": {
                  "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
                  "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de fichier"])}
                }
              },
              "loan_rules": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles de prêt"])},
                "columns": {
                  "max_loan_duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée maximale de prêt"])},
                  "concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs simultanés autorisés"])},
                  "lifetime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de vie"])},
                  "tokens": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prêts"])}
                }
              },
              "streaming_mode": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streaming"])},
                "columns": {
                  "streaming_concurrent_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'utilisateurs simultanés"])},
                  "loans_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de prêts"])},
                  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])}
                }
              },
              "protection": {
                "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protection"])},
                "columns": {
                  "protection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de protection"])},
                  "max_devices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appareils autorisés"])},
                  "copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier/coller"])},
                  "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer"])}
                }
              }
            }
          },
          "title_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
          "default_message_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite. Veuillez réessayer dans un instant."])},
          "filtrable_select": {
            "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une option"])},
            "tag_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour créer une étiquette"])},
            "select_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour sélectionner"])},
            "select_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour sélectionner le groupe"])},
            "selected_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionné"])},
            "deselect_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour enlever"])},
            "deselect_group_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appuyez sur Entrée pour enlever le groupe"])}
          },
          "catalog_publication": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue de publications"])},
            "saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres sauvegardés"])},
            "access_all_saved_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à tous les paramètres sauvegardés"])},
            "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement..."])},
            "settings_page": {
              "loading_creators": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chargement des créateurs..."])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres sauvegardés"])},
              "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
              "personal_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres personnels"])},
              "messages": {
                "destroy_settings": {
                  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès"])},
                  "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres supprimés avec succès."])}
                }
              },
              "modal": {
                "title": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Supprimer ", _interpolate(_named("name"))])},
                "confirm_delete_message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ces paramètres? Cette action est irréversible."])},
                "button": {
                  "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
                  "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
                }
              },
              "role": {
                "name": {
                  "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
                  "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance"])},
                  "finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
                  "librarian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothécaire"])},
                  "application": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Application"])},
                  "personal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnel"])}
                }
              },
              "filters": {
                "options": {
                  "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])}
                },
                "select": {
                  "creator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créateur"])},
                  "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "per_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Par page"])}
                },
                "input": {
                  "search": {
                    "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher par nom ou créateur"])},
                    "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
                  }
                },
                "button": {
                  "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])}
                }
              },
              "table": {
                "column": {
                  "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
                  "created_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
                  "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé le"])},
                  "general_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
                  "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])}
                },
                "button": {
                  "view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir"])},
                  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
                  "remove": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])}
                }
              }
            },
            "isbn_form": {
              "placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez les nouveaux ISBN séparés par un espace, une virgule ou un point-virgule"])},
              "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injecter les ISBN"])},
              "addButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
              "duplicateTagText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN en double"])},
              "limitTagsText": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Limite atteinte (max. ", _interpolate(_named("maxTags")), ")"])},
              "buttons": {
                "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
                "clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer"])}
              }
            },
            "isbn_results": {
              "result_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("of")), "/", _interpolate(_named("from")), " ISBN trouvés"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun ISBN trouvé"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "items_not_found": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("items")), " introuvables"])},
              "copy_not_found_isbns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier les ISBN introuvables"])}
            },
            "item_actions": {
              "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chargement..."])},
              "resynced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La resynchronisation a été mise en file d'attente. Veuillez allouer un délai de traitement de quelques minutes."])},
              "page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page"])},
              "out_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
              "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats"])},
              "lines_selected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lignes sélectionnées"])},
              "clear_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer la sélection"])},
              "search_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de recherche"])},
              "show_full_query": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher la requête complète"])},
              "items": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
              "dropdown_export_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les résultats"])},
              "dropdown_inject_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Injecter la liste des ISBN"])},
              "dropdown_add_selection_to_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter la sélection à une liste"])},
              "dropdown_apply_metadata_to_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer les métadonnées à la sélection"])},
              "dropdown_synchronize_selection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synchroniser la sélection avec les métadonnées de l'entrepôt"])}
            },
            "availabilities": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
              "customize_availability_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnaliser les paramètres de disponibilité"])},
              "networks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux"])},
              "territories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Territoire"])},
              "markets": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marché"])},
              "connectors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connecteur"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])}
            },
            "filters": {
              "libraries": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèque"])},
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
              "publication_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
              "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de catalogage"])},
              "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
              "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
              "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
              "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilité"])},
              "available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible"])},
              "unavailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non disponible"])},
              "unavailable__publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
              "unavailable__banned_not_epub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banni (non EPUB)"])},
              "unavailable__banned_publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banni (éditeur)"])},
              "unavailable__date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
              "unavailable__cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
              "unavailable__duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doublon"])},
              "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
              "catalogued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogué?"])},
              "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
              "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influence"])},
              "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
              "title_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre et sous-titre"])},
              "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur"])},
              "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
              "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
              "type_to_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez pour rechercher..."])},
              "save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les paramètres"])},
              "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])},
              "serie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
              "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
              "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
              "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])},
              "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
              "option_yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
              "option_no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
              "pick_several_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélection multiple"])},
              "market_public": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques publiques"])},
              "market_retail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grand public"])},
              "market_school": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bibliothèques scolaires"])},
              "market_classroom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture en classe"])},
              "market_academic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universitaire"])},
              "market_corporate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprises"])},
              "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de catalogue"])},
              "catalog_type_traditional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition traditionnelle"])},
              "catalog_type_self_published": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auto-édition"])},
              "catalog_type_public_domain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domaine public"])},
              "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
              "offer_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID de l'offre"])},
              "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'édition"])},
              "last_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
              "accessibility_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres d'accessibilité"])},
              "accessibilities": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilité"])},
              "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
              "list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste curatée"])},
              "award": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix littéraire"])},
              "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur québécois"])},
              "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture?"])},
              "not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])},
              "accessibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessibilité"])},
              "fully_accessible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pleinement accessible"])},
              "screen_reader_friendly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adapté aux lecteurs d'écrans"])},
              "full_audio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Audio intégral"])},
              "synchronized_text_and_audio_playback": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lecture synchronisée texte et audio"])},
              "sorter_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les colonnes visibles"])},
              "sorter_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les filtres visibles"])},
              "sorter_description_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur les boutons ci-dessous pour masquer ou afficher les colonnes correspondantes."])},
              "sorter_description_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez sur les boutons ci-dessous pour masquer ou afficher les filtres correspondants."])},
              "sorter_description_drag_and_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Glissez et déposez-les ensuite dans l'ordre souhaité (ou utilisez les flèches)."])},
              "sorter_cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
              "sorter_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
              "sorter_change_columns_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les colonnes"])},
              "sorter_change_filters_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les filtres"])},
              "sorter_reset_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les colonnes"])},
              "sorter_change_position_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la position des colonnes"])},
              "sorter_reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser la visibilité et la position des filtres"])},
              "sorter_change_position_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer la position des filtres"])}
            },
            "save_settings": {
              "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les paramètres"])},
              "visible_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres visibles"])},
              "filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs des filtres"])},
              "save_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les valeurs actuelles des filtres"])},
              "change_filter_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer les valeurs des filtres"])},
              "visible_values_in_results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs visibles dans les résultats"])},
              "saved_settings_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom des paramètres sauvegardés"])},
              "saved_settings_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type des paramètres sauvegardés"])},
              "personal_settings_only_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul vous aurez accès"])},
              "personal_settings_all_users": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous les utilisateurs de votre rôle"])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])},
              "error_unable_to_save_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur est survenue lors de la sauvegarde des paramètres."])}
            },
            "table": {
              "conditional": {
                "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
                "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
              },
              "columns": {
                "id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
                "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
                "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre & Sous-titre"])},
                "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
                "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur(s)"])},
                "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
                "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
                "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
                "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influences"])},
                "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
                "alternate_isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN papier"])},
                "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
                "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
                "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
                "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
                "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
                "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du Québec"])},
                "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
                "internal_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID interne"])},
                "provider": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
                "catalogued": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogué"])},
                "offers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offres"])},
                "audience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Public"])},
                "publishing_group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe d'édition"])},
                "catalog_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de catalogage"])},
                "catalog_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de catalogue"])},
                "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut + Raison d'indisponibilité"])},
                "accessibility_features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités d'accessibilité"])},
                "awards": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix littéraires"])}
              },
              "language": {
                "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
                "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
                "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
                "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
                "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russe"])},
                "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
                "ca": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalan"])},
                "eu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basque"])},
                "gl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Galicien"])},
                "uk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ukrainien"])},
                "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])}
              }
            }
          },
          "modal": {
            "button": {
              "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])}
            }
          }
        },
        "store_item_export": {
          "component": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter les résultats"])},
            "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner / désélectionner les valeurs dans la liste suivante pour définir quelles colonnes seront présentes dans l'exportation."])},
            "export_buttons_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez, à l'aide des boutons ci-dessous, les colonnes que vous souhaitez exporter."])},
            "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeurs à exporter"])},
            "buttons": {
              "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
              "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
            }
          },
          "request_export": {
            "message": {
              "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le rapport est en cours de génération. Vous recevrez un courriel lorsqu'il sera prêt."])},
              "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une erreur s'est produite lors du traitement de la demande, veuillez réessayer plus tard."])}
            }
          },
          "columns": {
            "cover": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre et sous-titre"])},
            "publication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de publication"])},
            "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auteur(s)"])},
            "publisher": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur"])},
            "collection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collection"])},
            "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
            "influence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Influences"])},
            "isbn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN"])},
            "last_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière mise à jour"])},
            "book_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
            "lists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Listes"])},
            "series": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Série"])},
            "from_quebec": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditeur québécois"])}
          },
          "is_from_quebec": {
            "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
            "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
          }
        }
      }
    }
  })
}
