<script setup>
  import { ref, onMounted, toRaw } from 'vue'
  import CatalogPublicationFilters from '../pages/CatalogPublicationFilters.vue';
  import PublicationTable from './catalog/PublicationTable.vue';
  import { useCatalogPublicationSettingStore } from '../stores/CatalogPublicationSettingStore.js';
  import { mapSettings, clearFiltersAndColumnsCache } from './helpers/CatalogPublicationFiltersHelper';
  import { useI18n } from 'vue-i18n';
  import { useRouter } from 'vue-router';
  import { useTableColumnStore } from '../stores/TableColumnStore.js';
  import { useUsersStore } from '../stores/UsersStore.js';

  const catalogPublicationSettingStore = useCatalogPublicationSettingStore();
  const usersStore = useUsersStore();
  const scope = 'components.catalog_publication.'
  const title = ref('Catalog Publication');
  const lang = ref();
  const router = useRouter();
  const settings = ref([]);
  const selectedSetting = ref(null);
  const selectedSettingId = ref(0);
  const currentUser = ref(null);
  const settingsLoaded = ref(false);
  const showFilters = ref(false);
  const publicationFiltersRef = ref(null);
  const params = new URLSearchParams(window.location.search);
  const returnUrl = '/frontend/catalog/publications?setting_id=';
  const { t } = useI18n();

  const {
    setOrder,
    getOriginals
  } = useTableColumnStore();
  document.title = title.value;
  lang.value = localStorage.getItem("lang");

  onMounted( async () => {
    if (params.has('setting_id')) {
      selectedSettingId.value = parseInt(params.get('setting_id'));
    }
    clearFiltersAndColumnsCache();

    await obtainUser();
    await obtainSettings();
  });

  const obtainUser = async () => {
    currentUser.value = await usersStore.fetchUser();
  };

  const obtainSettings = async () => {
    const settingsValues = await catalogPublicationSettingStore.fetchSettings();
    settings.value = mapSettings(toRaw(settingsValues.data), currentUser.value.data.id);
    if (settings.value.length > 0) settingsLoaded.value = true;
    showFilters.value = true;
  };

  const redirectWithSettings = async (e) => {
    showFilters.value = false;
    const settingId = parseInt(e.target.value);
    if (settingId == 0) {
      await setOrder(getOriginals('filters'), 'filters');
      await setOrder(getOriginals('columns'), 'columns');
      router.push(`${returnUrl}0&set_values=false`);
      return;
    }
    const setting = settings.value.find(setting => setting.id === settingId);
    selectedSetting.value = setting;
    selectedSettingId.value = settingId;
    const query = selectedSetting.value.filtersUrlQuery;
    await setOrder(toRaw(selectedSetting.value.filterPositions), 'filters');
    await setOrder(toRaw(selectedSetting.value.columnPositions), 'columns');
    router.push(`${returnUrl}${settingId}&${query}&set_values=true`);
  };
</script>

<template>
  <div class="container">
    <div id="teleport-alert"></div>
    <div class="row align-middle">
      <div class="col-4">&nbsp;</div>
      <div class="col-4">
        <h1 class="title">{{t(`${scope}title`)}}</h1>
      </div>
      <div class="col-4">
        <div class="row align-items-center">
          <div class="col-6 my-4 text-end">
            <label class="form-label">
              <strong>
                {{t(`${scope}saved_settings`)}}:
              </strong>
            </label>
          </div>
          <div class="col-6 my-4">
            <select
              class="form-select"
              @input="redirectWithSettings($event)"
              v-model="selectedSettingId"
            >
              <option value="0" >- {{t(`${scope}filters.none`)}} -</option>
              <option v-for="(setting) in settings" :value="setting.id">
                {{ setting.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-4" v-if="!settingsLoaded">
        &nbsp;
      </div>
    </div>
    
    <div class="with_bst">
      <CatalogPublicationFilters
        :lang="lang"
        v-if="showFilters && settingsLoaded"
        :urlQuery="selectedSetting ? selectedSetting.filtersUrlQuery : null"
        ref="publicationFiltersRef"
      />
      <hr class="hr" />
    </div>
    <PublicationTable ref="publicationTable" />
  </div>
</template>

<style scoped lang="scss">
  :deep() {
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  :deep(.custom-offcanvas) {
    min-height: 540px !important;
  }
  .title {
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18px;
  }
</style>
