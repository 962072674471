<script setup>
  import { computed, ref } from 'vue'
  import Sorter from './catalog_publication_fields/Sorter.vue';
  import SorterMenu from './catalog_publication_fields/SorterMenu.vue';
  import { useTableColumnStore } from '../stores/TableColumnStore.js';
  import { useI18n } from 'vue-i18n';
  import { useCatalogPublicationSettingStore } from '../stores/CatalogPublicationSettingStore.js';
  import { useRoute, useRouter } from 'vue-router'
  import { getQueryParams } from './helpers/CatalogPublicationFiltersHelper.js';
  
  const { t } = useI18n();
  const {
    getFiltersCanBeHidden,
    activeFilters,
    inactiveFilters,
    columns,
    activeColumns,
    inactiveColumns,
    getColumnsCanBeHidden,
    orderColumns,
    orderFilters
  } = useTableColumnStore();

  const route = useRoute();
  const router = useRouter();
  const title = ref('Catalog Publication');
  const catalogPublicationsStore = useCatalogPublicationSettingStore();

  const lang = ref();
  const finalValues = ref({
    name: '',
    save_current_filters: false,
    general_access: 'restricted',
    filters_url_query: new URLSearchParams(route.query).toString()
  });
  const scope = 'components.catalog_publication.save_settings.'
  const showChangePositionElement = ref(false);
  document.title = title.value;
  lang.value = localStorage.getItem("lang");

  const togglePositionMenu = () => {
    showChangePositionElement.value = true
  };

  const queryParams = computed(() => {
    const params = getQueryParams(route.query, false);
      return params;
  });

  const sendData = async () => {
    const activeOrderedFilters = [];
    for (let i = 0; i < orderFilters.length; i++) {
      if (activeFilters.includes(orderFilters[i])) {
        activeOrderedFilters.push(orderFilters[i]);
      }
    }
    
    // same for the columns
    const activeOrderedColumns = [];
    for (let i = 0; i < orderColumns.length; i++) {
      if (activeColumns.includes(orderColumns[i])) {
        activeOrderedColumns.push(orderColumns[i]);
      }
    }

    const savedSettings = await catalogPublicationsStore.saveSettings(
      finalValues.value.name,
      // finalValues.value.save_current_filters,
      finalValues.value.general_access,
      finalValues.value.filters_url_query,
      activeOrderedFilters,
      activeOrderedColumns.filter(e => e !== 'id')
    );
    const newSettingId = savedSettings.data.data.id;
    if (savedSettings) {
      const urlParams = window.location.href.split('?');
      router.push(`/frontend/catalog/publications?${urlParams[1]}&saved=true&set_values=true&setting_id=${newSettingId}`);
    } else {
      var toast = document.getElementById('liveToast')
      var bToast = bootstrap.Toast.getOrCreateInstance(toast);
      bToast.show();
    }
  };

</script>

<template>
  <div class="with_bst">
    <div class="container-md">
      <input type="hidden" v-model="finalValues.filters_url_query" />

      <div class="row">
        <h1 class="title">{{t(`${scope}title`)}}</h1>
        
        <h4>{{t(`${scope}visible_filters`)}}</h4>
        <Sorter
          @closeSorter="true"
          :activeFilters="activeFilters"
          :inactiveFilters="inactiveFilters"
          :type="'filters'"
          :showDescription="false"
          :showCancelAndConfirmButtons="false"
          :showCloseButton="false"
          id="visibleFilters"
        />
        <SorterMenu
          id="sorterMenuFilters"
          @togglePositionMenu="togglePositionMenu"
          :activeFilters="activeFilters"
          :inactiveFilters="inactiveFilters"
          :title="t('components.catalog_publication.filters.sorter_change_filters_title')"
          :getCanBeHidden="getFiltersCanBeHidden"
          :type="'filters'"
          :showBorder="true"
          class="my-2"
        />

        <h5>{{t(`${scope}filter_values`)}}</h5>
        <div v-html="queryParams"></div>

        <div class="content my-3">
          <div class="form-check">
            <!-- This one is missing in PublicationSettingsController -->
            <input
              class="form-check-input" 
              type="checkbox" 
              v-model="finalValues.save_current_filters" 
              name="save_current_values"
            >
            <label class="form-check-label" for="flexCheckDefault">
              {{t(`${scope}save_filter_values`)}}
            </label>
          </div>
        </div>

        <hr class="hr mb-5" />
        <h4>{{t(`${scope}visible_values_in_results`)}}</h4>
        <Sorter
          :columns="columns"
          :activeColumns="activeColumns"
          :inactiveColumns="inactiveColumns"
          :type="'columns'"
          :orderColumns="orderColumns"
          :showDescription="false"
          :showCancelAndConfirmButtons="false"
          :showCloseButton="false"
          id="visibleColumns"
        />
        <SorterMenu
          id="sorterMenuColumns"
          :columns="columns"
          :activeColumns="activeColumns"
          :inactiveColumns="inactiveColumns"
          :orderColumns="orderColumns"
          :title="t('components.catalog_publication.filters.sorter_change_columns_title')"
          :getCanBeHidden="getColumnsCanBeHidden"
          :type="'columns'"
          class="my-2"
        />
        
        <hr class="hr mb-4 mt-3" />

        <h4>{{t(`${scope}saved_settings_name`)}}</h4>
        <div class="content mb-5">
          <input
            type="text"
            class="form-control"
            v-model="finalValues.name"
          >
        </div>

        <h4 class="">{{t(`${scope}saved_settings_type`)}}</h4>
        <div class="content">
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio" 
              value="restricted"
              v-model="finalValues.general_access"
            >
            <label class="form-check-label" for="flexRadioDefault1">
              {{t(`${scope}personal_settings_only_you`)}}
            </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input" 
              type="radio" 
              value="shared"
              v-model="finalValues.general_access"
            >
            <label class="form-check-label" for="flexRadioDefault2">
              {{t(`${scope}personal_settings_all_users`)}}
            </label>
          </div>
        </div>
      </div>

      <div
        class="d-flex flex-row justify-content-start order-confirm-or-cancel-buttons mt-5"
        v-if="!catalogPublicationsStore.saving"
      >
        <button
          variant="success"
          class="btn btn-success me-3"
          v-if="finalValues.name.length > 3"
          @click="sendData"
        >
          {{t(`components.catalog_publication.filters.sorter_confirm`)}}
        </button>
        <button
          variant="danger"
          class="btn btn-danger"
          @click="route.options.history.state.back"
        >
          {{t(`components.catalog_publication.filters.sorter_cancel`)}}
        </button>
      </div>

    </div>


    <div class="position-fixed bottom-0 end-0 p-3" style="z-index: 11">
      <div id="liveToast" class="toast hide" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="toast-header">
          <strong class="me-auto">
            {{t(`${scope}error`)}}
          </strong>
          <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body">
          {{t(`${scope}error_unable_to_save_settings`)}}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
  :deep() {
    @import '../../../node_modules/bootstrap/dist/css/bootstrap';
    @import '../../../node_modules/bootstrap-vue-next/dist/bootstrap-vue-next';
  }

  :deep(.custom-offcanvas) {
    min-height: 400px !important;
  }
  .title {
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 18px;
  }
  .form-check-input {
    border-color: gray;
  }
</style>
